import Cookies from "universal-cookie";
import { Box, Typography } from "@mui/material";
import Logo from './../../assets/images/logo.png';
import { useState, useEffect } from "react";
import { FaUserPlus } from "react-icons/fa";
import { PiUsersThreeDuotone } from "react-icons/pi";
import { FaGifts } from "react-icons/fa6";
import { HiGiftTop } from "react-icons/hi2";
import { IoMdFootball } from "react-icons/io";
import { HiOutlineCash } from "react-icons/hi";
import { PiCashRegisterThin } from "react-icons/pi";
import { BiMoneyWithdraw } from "react-icons/bi";
import { FaLine } from "react-icons/fa6";
import { MdClose } from "react-icons/md";
import { Link } from "react-router-dom";
import { FaTelegramPlane } from "react-icons/fa";
import { FaRegStar } from "react-icons/fa";
import { FaCheckDouble } from "react-icons/fa6";
import { IoHome } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { FaClipboardList } from "react-icons/fa";
import { GrList } from "react-icons/gr";
import LogoutIcon from '@mui/icons-material/Logout';
import Swal from "sweetalert2";

interface PropsType {
    isOpenState: boolean;
    setIsOpenState: (state: boolean) => void;
    setDepositWithdrawIsOpenState: (state: boolean) => void;
    setIsOpenLoginRegisterState: (state: boolean) => void;
    setDepositOrWithdraw: (state: number) => void;
    setLoginOrRegister: (state: number) => void;
    setDepositWithdrawTransactionsIsOpenState: (state: boolean) => void;
    setDepositOrWithdrawTransactions: (state: number) => void;
}

const MenuMain = ({ isOpenState, setIsOpenState, setDepositWithdrawIsOpenState, setIsOpenLoginRegisterState, setDepositOrWithdraw, setLoginOrRegister, setDepositWithdrawTransactionsIsOpenState, setDepositOrWithdrawTransactions }: PropsType) => {
    const cookies = new Cookies(null, { path: '/' });
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState<boolean>(false);

    useEffect(() => {
        setIsOpen(isOpenState);
    }, [isOpenState]);

    const logout = () => {
        cookies.remove('access_token');
        Swal.fire({
            icon: 'success',
            title: 'แจ้งเตือน',
            text: 'ออกจากระบบเรียบร้อยแล้วค่ะ'
        }).then((res) => {
            if(res)
            {
                window.location.reload();
            }
        });
    }

    const customHref = (url: string) => {
        const userAgent = navigator.userAgent.toLowerCase();
        const safari = /safari/.test(userAgent) && !/chrome/.test(userAgent);

        if(safari)
        {
            window.location.href = url;
        }
        else
        {
            const link = document.createElement('a');
            link.href = url;
            link.target = '_blank';
            link.click();
        }
    }

    return (
        <>
            <Box
                position={`fixed`}
                width={`100%`}
                height={`100%`}
                zIndex={40}
                display={isOpen ? `block` : `none`}
                flexDirection={`column`}
                justifyContent={`center`}
                alignContent={`center`}
                alignItems={`center`}
                color={`black`}
                gap={2}
                top={0}
                onClick={() => setIsOpenState(false)}
                sx={{
                    backgroundColor: {
                        xs: 'rgba(255, 255, 255, .9)',
                        md: 'rgba(255, 255, 255, .8)'
                    }
                }}
            >
                <Box
                    display={`flex`}
                    flexDirection={`column`}
                    justifyContent={`center`}
                    alignContent={`center`}
                    alignItems={`center`}
                    gap={2}
                    sx={{ px: { xs: 3, md: 0 } }}
                >
                    <Box
                        display={`flex`} 
                        justifyContent={`center`}
                    >
                        <Link to={'/'}>
                            <img src={Logo} alt="Logo" width={`210px`} style={{ maxWidth: '210px' }} />
                        </Link>
                    </Box>
                    <Box display={`flex`} gap={2}>
                        <Box sx={{
                            backgroundColor: 'white',
                            width: 200,
                            height: 90,
                            borderRadius: 3,
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            alignContent: 'center',
                            alignItems: 'center',
                            transition: 'all .2s',
                            '&:hover': {
                                backgroundColor: '#dae8ff'
                            },
                            boxShadow: '10px 10px 10px rgba(0, 0, 0, 0.1)',
                            cursor: 'pointer'
                        }}  onClick={() => navigate('/')}>
                            <Typography align="center">
                                <IoHome fontSize={42} color="#7600cc" />
                            </Typography>
                            <Typography color={`#7600cc`} align="center">
                                หน้าแรก
                            </Typography>
                        </Box>
                        <Box sx={{
                            backgroundColor: 'white',
                            width: 200,
                            height: 90,
                            borderRadius: 3,
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            alignContent: 'center',
                            alignItems: 'center',
                            transition: 'all .2s',
                            '&:hover': {
                                backgroundColor: '#dae8ff'
                            },
                            boxShadow: '10px 10px 10px rgba(0, 0, 0, 0.1)',
                            cursor: 'pointer'
                        }} onClick={() => navigate('/promotions')}>
                            <Typography align="center">
                                <HiGiftTop fontSize={42} color="#7600cc" />
                            </Typography>
                            <Typography color={`#7600cc`} align="center">
                                โปรโมชั่น
                            </Typography>
                        </Box>
                    </Box>
                    <Box display={`flex`} gap={2}>
                        {
                            cookies.get('access_token') ? <>
                                <Box sx={{
                                    backgroundColor: 'white',
                                    width: 200,
                                    height: 90,
                                    borderRadius: 3,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexDirection: 'column',
                                    alignContent: 'center',
                                    alignItems: 'center',
                                    transition: 'all .2s',
                                    '&:hover': {
                                        backgroundColor: '#dae8ff'
                                    },
                                    boxShadow: '10px 10px 10px rgba(0, 0, 0, 0.1)',
                                    cursor: 'pointer'
                                }} onClick={() => {
                                    setDepositOrWithdraw(1);
                                    setDepositWithdrawIsOpenState(true);
                                }}>
                                    <Typography align="center">
                                        <HiOutlineCash fontSize={42} color="#7600cc" />
                                    </Typography>
                                    <Typography color={`#7600cc`} align="center">
                                        ฝากเงิน
                                    </Typography>
                                </Box>
                                <Box sx={{
                                    backgroundColor: 'white',
                                    width: 200,
                                    height: 90,
                                    borderRadius: 3,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexDirection: 'column',
                                    alignContent: 'center',
                                    alignItems: 'center',
                                    transition: 'all .2s',
                                    '&:hover': {
                                        backgroundColor: '#dae8ff'
                                    },
                                    boxShadow: '10px 10px 10px rgba(0, 0, 0, 0.1)',
                                    cursor: 'pointer'
                                }} onClick={() => {
                                    setDepositOrWithdraw(2);
                                    setDepositWithdrawIsOpenState(true);
                                }}>
                                    <Typography align="center">
                                        <BiMoneyWithdraw fontSize={42} color="#7600cc" />
                                    </Typography>
                                    <Typography color={`#7600cc`} align="center">
                                        ถอนเงิน
                                    </Typography>
                                </Box>
                            </> : <>
                                <Box sx={{
                                    backgroundColor: 'white',
                                    width: 200,
                                    height: 90,
                                    borderRadius: 3,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexDirection: 'column',
                                    alignContent: 'center',
                                    alignItems: 'center',
                                    transition: 'all .2s',
                                    '&:hover': {
                                        backgroundColor: '#dae8ff'
                                    },
                                    boxShadow: '10px 10px 10px rgba(0, 0, 0, 0.1)',
                                    cursor: 'pointer'
                                }} onClick={() => {
                                    setLoginOrRegister(2);
                                    setIsOpenLoginRegisterState(true);
                                }}>
                                    <Typography align="center">
                                        <FaUserPlus fontSize={42} color="#7600cc" />
                                    </Typography>
                                    <Typography color={`#7600cc`} align="center">
                                        สมัครสมาชิก
                                    </Typography>
                                </Box>
                                <Box sx={{
                                    backgroundColor: 'white',
                                    width: 200,
                                    height: 90,
                                    borderRadius: 3,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexDirection: 'column',
                                    alignContent: 'center',
                                    alignItems: 'center',
                                    transition: 'all .2s',
                                    '&:hover': {
                                        backgroundColor: '#dae8ff'
                                    },
                                    boxShadow: '10px 10px 10px rgba(0, 0, 0, 0.1)',
                                    cursor: 'pointer'
                                }} onClick={() => {
                                    setLoginOrRegister(1);
                                    setIsOpenLoginRegisterState(true);
                                }}>
                                    <Typography align="center">
                                        <PiUsersThreeDuotone fontSize={42} color="#7600cc" />
                                    </Typography>
                                    <Typography color={`#7600cc`} align="center">
                                        เข้าสู่ระบบ
                                    </Typography>
                                </Box>
                            </>
                        }
                        
                    </Box>
                    <Box display={`flex`} gap={2}>
                        <Box sx={{
                            backgroundColor: 'white',
                            width: 200,
                            height: 90,
                            borderRadius: 3,
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            alignContent: 'center',
                            alignItems: 'center',
                            transition: 'all .2s',
                            '&:hover': {
                                backgroundColor: '#dae8ff'
                            },
                            boxShadow: '10px 10px 10px rgba(0, 0, 0, 0.1)',
                            cursor: 'pointer'
                        }} onClick={() => {
                            navigate('/payrate');
                        }}>
                            <Typography align="center">
                                <FaRegStar fontSize={42} color="#7600cc" />
                            </Typography>
                            <Typography color={`#7600cc`} align="center">
                                อัตราจ่ายหวย
                            </Typography>
                        </Box>
                        <Box sx={{
                            backgroundColor: 'white',
                            width: 200,
                            height: 90,
                            borderRadius: 3,
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            alignContent: 'center',
                            alignItems: 'center',
                            transition: 'all .2s',
                            '&:hover': {
                                backgroundColor: '#dae8ff'
                            },
                            boxShadow: '10px 10px 10px rgba(0, 0, 0, 0.1)',
                            cursor: 'pointer'
                        }} onClick={() => navigate('/results')}>
                            <Typography align="center">
                                <FaCheckDouble fontSize={42} color="#7600cc" />
                            </Typography>
                            <Typography color={`#7600cc`} align="center">
                                ตรวจหวย
                            </Typography>
                        </Box>
                    </Box>
                    {
                        cookies.get('access_token') ? <Box display={`flex`} gap={2}>
                            <Box sx={{
                                backgroundColor: 'white',
                                width: 200,
                                height: 90,
                                borderRadius: 3,
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                alignContent: 'center',
                                alignItems: 'center',
                                transition: 'all .2s',
                                '&:hover': {
                                    backgroundColor: '#dae8ff'
                                },
                                boxShadow: '10px 10px 10px rgba(0, 0, 0, 0.1)',
                                cursor: 'pointer'
                            }} onClick={() => {
                                navigate('/bills');
                            }}>
                                <Typography align="center">
                                    <FaClipboardList fontSize={42} color="#7600cc" />
                                </Typography>
                                <Typography color={`#7600cc`} align="center">
                                    โพยหวย
                                </Typography>
                            </Box>
                            <Box sx={{
                                backgroundColor: 'white',
                                width: 200,
                                height: 90,
                                borderRadius: 3,
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                alignContent: 'center',
                                alignItems: 'center',
                                transition: 'all .2s',
                                '&:hover': {
                                    backgroundColor: '#dae8ff'
                                },
                                boxShadow: '10px 10px 10px rgba(0, 0, 0, 0.1)',
                                cursor: 'pointer'
                            }} onClick={() => setDepositWithdrawTransactionsIsOpenState(true)}>
                                <Typography align="center">
                                    <GrList fontSize={42} color="#7600cc" />
                                </Typography>
                                <Typography color={`#7600cc`} align="center">
                                    ประวัติฝากถอน
                                </Typography>
                            </Box>
                        </Box> : null
                    }
                    {
                        cookies.get('access_token') ? <Box display={`flex`} gap={2}>
                            <Box display={`flex`} gap={1}>
                                <Box sx={{
                                    background: 'linear-gradient(to bottom, #a5e8a5 1%, #11c212 99%)',
                                    width: 100,
                                    height: 90,
                                    borderRadius: 3,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexDirection: 'column',
                                    alignContent: 'center',
                                    alignItems: 'center',
                                    transition: 'all .2s',
                                    '&:hover': {
                                        background: 'linear-gradient(to bottom, #a5e8a5 1%, #11c212 99%)',
                                    },
                                    boxShadow: '10px 10px 10px rgba(0, 0, 0, 0.1)',
                                    cursor: 'pointer'
                                }} onClick={() => {
                                    customHref("https://lin.ee/JeEyMfi");
                                }}>
                                    <Typography align="center">
                                        <FaLine fontSize={32} color="#fff" />
                                    </Typography>
                                    <Typography fontSize={14} color={`#fff`} align="center">
                                        LINE
                                    </Typography>
                                </Box>
                                <Box sx={{
                                    background: 'linear-gradient(to bottom, #7bcff7 1%, #47b4e5 99%)',
                                    width: 100,
                                    height: 90,
                                    borderRadius: 3,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexDirection: 'column',
                                    alignContent: 'center',
                                    alignItems: 'center',
                                    transition: 'all .2s',
                                    '&:hover': {
                                        background: 'linear-gradient(to bottom, #7bcff7 1%, #47b4e5 99%)',
                                    },
                                    boxShadow: '10px 10px 10px rgba(0, 0, 0, 0.1)',
                                    cursor: 'pointer'
                                }} onClick={() => {
                                    customHref("https://t.me/ohpg88com");
                                }}>
                                    <Typography align="center">
                                        <FaTelegramPlane fontSize={32} color="#fff" />
                                    </Typography>
                                    <Typography fontSize={14} color={`#fff`} align="center">
                                        Telegram
                                    </Typography>
                                </Box>
                            </Box>
                            <Box sx={{
                                background: 'linear-gradient(to bottom, #f90a2a 1%, #c30a2a 99%)',
                                width: 200,
                                height: 90,
                                borderRadius: 3,
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                alignContent: 'center',
                                alignItems: 'center',
                                transition: 'all .2s',
                                '&:hover': {
                                    background: 'linear-gradient(to bottom, #f90a2a 1%, #c30a2a 99%)',
                                },
                                boxShadow: '10px 10px 10px rgba(0, 0, 0, 0.1)',
                                cursor: 'pointer'
                            }} onClick={logout}>
                                <Typography align="center">
                                    <LogoutIcon sx={{ color: '#fff', fontSize: 42 }} />
                                </Typography>
                                <Typography color={`#fff`} align="center">
                                    ออกจากระบบ
                                </Typography>
                            </Box>
                        </Box> : <Box display={`flex`} gap={2}>
                            <Box sx={{
                                background: 'linear-gradient(to bottom, #a5e8a5 1%, #11c212 99%)',
                                width: 200,
                                height: 90,
                                borderRadius: 3,
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                alignContent: 'center',
                                alignItems: 'center',
                                transition: 'all .2s',
                                '&:hover': {
                                    background: 'linear-gradient(to bottom, #a5e8a5 1%, #11c212 99%)',
                                },
                                boxShadow: '10px 10px 10px rgba(0, 0, 0, 0.1)',
                                cursor: 'pointer'
                            }} onClick={() => {
                                customHref("https://lin.ee/JeEyMfi");
                            }}>
                                <Typography align="center">
                                    <FaLine fontSize={42} color="#fff" />
                                </Typography>
                                <Typography color={`#fff`} align="center">
                                    LINE
                                </Typography>
                            </Box>
                            <Box sx={{
                                background: 'linear-gradient(to bottom, #7bcff7 1%, #47b4e5 99%)',
                                width: 200,
                                height: 90,
                                borderRadius: 3,
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                alignContent: 'center',
                                alignItems: 'center',
                                transition: 'all .2s',
                                '&:hover': {
                                    background: 'linear-gradient(to bottom, #7bcff7 1%, #47b4e5 99%)',
                                },
                                boxShadow: '10px 10px 10px rgba(0, 0, 0, 0.1)',
                                cursor: 'pointer'
                            }} onClick={() => {
                                customHref("https://t.me/ohpg88com");
                            }}>
                                <Typography align="center">
                                    <FaTelegramPlane fontSize={42} color="#fff" />
                                </Typography>
                                <Typography color={`#fff`} align="center">
                                    Telegram
                                </Typography>
                            </Box>
                        </Box>
                    }
                    {/* <Box display={`flex`} gap={2}>
                        <Box display={`flex`} gap={1}>
                            <Box sx={{
                                background: 'linear-gradient(to bottom, #a5e8a5 1%, #11c212 99%)',
                                width: 100,
                                height: 90,
                                borderRadius: 3,
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                alignContent: 'center',
                                alignItems: 'center',
                                transition: 'all .2s',
                                '&:hover': {
                                    background: 'linear-gradient(to bottom, #a5e8a5 1%, #11c212 99%)',
                                },
                                boxShadow: '10px 10px 10px rgba(0, 0, 0, 0.1)',
                                cursor: 'pointer'
                            }}>
                                <Typography align="center">
                                    <FaLine fontSize={32} color="#fff" />
                                </Typography>
                                <Typography fontSize={14} color={`#fff`} align="center">
                                    LINE
                                </Typography>
                            </Box>
                            <Box sx={{
                                background: 'linear-gradient(to bottom, #7bcff7 1%, #47b4e5 99%)',
                                width: 100,
                                height: 90,
                                borderRadius: 3,
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                alignContent: 'center',
                                alignItems: 'center',
                                transition: 'all .2s',
                                '&:hover': {
                                    background: 'linear-gradient(to bottom, #7bcff7 1%, #47b4e5 99%)',
                                },
                                boxShadow: '10px 10px 10px rgba(0, 0, 0, 0.1)',
                                cursor: 'pointer'
                            }}>
                                <Typography align="center">
                                    <FaTelegramPlane fontSize={32} color="#fff" />
                                </Typography>
                                <Typography fontSize={14} color={`#fff`} align="center">
                                    Telegram
                                </Typography>
                            </Box>
                        </Box>
                        <Box sx={{
                            background: 'linear-gradient(to bottom, #f90a2a 1%, #c30a2a 99%)',
                            width: 200,
                            height: 90,
                            borderRadius: 3,
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            alignContent: 'center',
                            alignItems: 'center',
                            transition: 'all .2s',
                            '&:hover': {
                                background: 'linear-gradient(to bottom, #f90a2a 1%, #c30a2a 99%)',
                            },
                            boxShadow: '10px 10px 10px rgba(0, 0, 0, 0.1)',
                            cursor: 'pointer'
                        }} onClick={logout}>
                            <Typography align="center">
                                <LogoutIcon sx={{ color: '#fff', fontSize: 42 }} />
                            </Typography>
                            <Typography color={`#fff`} align="center">
                                ออกจากระบบ
                            </Typography>
                        </Box>
                    </Box> */}
                    <Box display={`flex`} justifyContent={`center`} alignContent={`center`} alignItems={`center`} sx={{
                        color: '#7600cc',
                        '&:hover': {
                            color: '#cc2130'
                        },
                        transition: 'all .2s'
                    }} onClick={() => setIsOpenState(false)}>
                        <MdClose fontSize={24} cursor={'pointer'} />
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default MenuMain;