import { Box, Button, Divider, Typography } from "@mui/material";
import Logo from './../../assets/images/logo.png';
import { useState, useEffect, useRef, MouseEvent, ChangeEvent, FormEvent } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { ApiService } from "../../services/api.service";
import Swal from "sweetalert2";
import SubDeposit from "./SubDeposit";
import SubWithdraw from "./SubWithdraw";

interface PropsType {
    isOpenState: boolean;
    setIsOpenState: (state: boolean) => void;
    depositOrWithdraw: number;
    setDepositOrWithdraw: (state: number) => void;
}

const DepositWithdrawMain = ({ isOpenState, setIsOpenState, depositOrWithdraw, setDepositOrWithdraw }: PropsType) => {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState<boolean>(false);

    useEffect(() => {
        setIsOpen(isOpenState);
    }, [isOpenState]);

    return (
        <>
            <Box
                position={`fixed`}
                width={`100%`}
                height={`100%`}
                display={isOpen ? `block` : `none`}
                flexDirection={`column`}
                justifyContent={`center`}
                alignContent={`center`}
                alignItems={`center`}
                color={`black`}
                zIndex={40}
                gap={2}
                top={0}
                sx={{
                    backgroundColor: {
                        xs: 'rgba(0, 0, 0, .6)',
                        md: 'rgba(0, 0, 0, .5)'
                    },
                    overflow: 'scroll'
                }}
            >
                <Box
                    display={`flex`}
                    flexDirection={`column`}
                    justifyContent={`center`}
                    alignContent={`center`}
                    alignItems={`center`}
                    gap={2}
                    sx={{ px: { xs: 3, md: 0 } }}
                >
                    <Box display={`flex`} gap={2}>
                        <Box sx={{
                            backgroundColor: 'white',
                            borderRadius: '15px 15px 15px 15px',
                            minWidth: '350px',
                            width: 'auto',
                            maxWidth: {
                                xs: '400px !important',
                                sm: '500px !important',
                                md: '600px !important',
                                lg: '600px !important'
                            }
                        }}>
                            <Box display={`flex`} justifyContent={`space-around`}>
                                <Box display={`flex`} justifyContent={`center`} alignContent={`center`} alignItems={`center`} p={2} bgcolor={depositOrWithdraw === 2 ? `white` : `#570394`} width={`100%`}
                                    sx={{
                                        borderRadius: '15px 0px 0px 0px',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => setDepositOrWithdraw(2)}
                                >
                                    <Typography color={depositOrWithdraw === 2 ? `#570394` : `white`}>
                                        ถอนเงิน
                                    </Typography>
                                </Box>
                                <Box display={`flex`} justifyContent={`center`} alignContent={`center`} alignItems={`center`} p={2} bgcolor={depositOrWithdraw === 1 ? `white` : `#570394`} width={`100%`}
                                    sx={{
                                        borderRadius: '0px 15px 0px 0px',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => setDepositOrWithdraw(1)}
                                >
                                    <Typography color={depositOrWithdraw === 1 ? `#570394` : `white`}>
                                        ฝากเงิน
                                    </Typography>
                                </Box>
                            </Box>
                            {
                                depositOrWithdraw === 1 ? <SubDeposit /> : <SubWithdraw />
                            }
                            <Box pb={1} px={2}>
                                <Divider sx={{ mt: 1 }} />
                                <Button fullWidth sx={
                                    {
                                        mt: 1,
                                        backgroundColor: '#9c9c9c',
                                        color: 'white',
                                        '&:hover': {
                                            backgroundColor: '#696969',
                                            color: 'white',
                                        }
                                    }
                                } onClick={() => setIsOpenState(false)}>
                                    <Typography fontSize={16} fontWeight={500}>
                                        ปิดหน้าต่าง
                                    </Typography>
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default DepositWithdrawMain;