import { Box } from "@mui/material";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import './../../assets/css/banner-bullet.css';

import BannerBackground from './../../assets/images/banner-bg.png';
// import BannerDefault from './../../assets/images/banner/1600x576.png';
import Banner1 from './../../assets/images/banner/b1_0.jpg';
import Banner2 from './../../assets/images/banner/b2_0.jpg';
import Banner3 from './../../assets/images/banner/b3_0.jpg';
import Banner4 from './../../assets/images/banner/b4_0.jpg';
import Banner5 from './../../assets/images/banner/b5_0.jpg';
import Banner6 from './../../assets/images/banner/b6_0.jpg';
import Banner7 from './../../assets/images/banner/b7_0.jpg';

import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

interface PropsType {
    disableBackground: boolean
}

const Banner = ({ disableBackground }: PropsType) => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    });

    let location = useLocation();
    const [pathName, setPathName] = useState<string | null>(null);

    useEffect(() => {
        setPathName(location.pathname);
    }, [location]);

    return (
        !pathName?.includes("/play/") ? <Box width={`100%`} display={`flex`} justifyContent={`center`} alignContent={`center`} alignItems={`center`} sx={{
            pt: 5,
            pb: 2.5,
            background: !disableBackground ? `url('${BannerBackground}')` : 'unset',
            backgroundSize: !disableBackground ? 'cover' : 'unset'
        }}>
            <Swiper
                pagination={true}
                slidesPerView={isDesktopOrLaptop ? 1.5 : 1}
                centeredSlides={true}
                spaceBetween={10}
                modules={[Pagination]}
                loop={true}
                className="my-swiper-container"
            >
                <SwiperSlide style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center'
                }}>
                    <img src={Banner1} alt="Banner1" width={`95%`} style={{ borderRadius: '15px' }} />
                </SwiperSlide>
                <SwiperSlide style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center'
                }}>
                    <img src={Banner2} alt="Banner2" width={`95%`} style={{ borderRadius: '15px' }} />
                </SwiperSlide>
                <SwiperSlide style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center'
                }}>
                    <img src={Banner3} alt="Banner3" width={`95%`} style={{ borderRadius: '15px' }} />
                </SwiperSlide>
                <SwiperSlide style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center'
                }}>
                    <img src={Banner4} alt="Banner4" width={`95%`} style={{ borderRadius: '15px' }} />
                </SwiperSlide>
                <SwiperSlide style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center'
                }}>
                    <img src={Banner5} alt="Banner5" width={`95%`} style={{ borderRadius: '15px' }} />
                </SwiperSlide>
                <SwiperSlide style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center'
                }}>
                    <img src={Banner6} alt="Banner6" width={`95%`} style={{ borderRadius: '15px' }} />
                </SwiperSlide>
                <SwiperSlide style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center'
                }}>
                    <img src={Banner7} alt="Banner7" width={`95%`} style={{ borderRadius: '15px' }} />
                </SwiperSlide>
            </Swiper>
        </Box> : null
    )
}

export default Banner;