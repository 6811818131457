import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ApiService } from "../../services/api.service";
import Swal from "sweetalert2";
import { favouritesGame, gameListType, lastPlayGameType, providersType } from "../../types";
import Cookies from "universal-cookie";

import { Box, Button, Container, Divider, Grid, Typography } from "@mui/material";
import { FaPlay } from "react-icons/fa6";
import { FaRegHeart } from "react-icons/fa";
import { TiHeartFullOutline } from "react-icons/ti";
import DiceSpin from "../Loading/DiceSpin";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import './../../assets/css/gamehome.css';
import { useMediaQuery } from "react-responsive";
import toast, { Toaster } from "react-hot-toast";

interface PropsType {
    game: string
}

const GameTab = ({ game }: PropsType) => {
    const cookies = new Cookies(null, {path: '/'});
    const navigate = useNavigate();
    const [gameList, setGameList] = useState<gameListType[]>([]);
    const [providers, setProviders] = useState<providersType[]>([]);
    const [currentProviders, setCurrentProviders] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [providerOffsets, setProviderOffsets] = useState<number>(0);
    const [providerBottom, setProviderBottom] = useState<boolean>(false);
    const [providerHeight, setProviderHeight] = useState<number | null>(null);
    const [favouritesGame, setFavouritesGame] = useState<favouritesGame[]>([]);
    const [lastPlayGame, setLastPlayGame] = useState<lastPlayGameType[]>([]);
    const [addingFav, setAddingFav] = useState<boolean>(false);
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 640px)'
    });

    useEffect(() => {
        setProviderOffsets(0);
        setProviderBottom(false);
        window.scrollTo({
            top: 0,     // เลื่อนขึ้นไปที่ตำแหน่ง 0 (บนสุด)
            behavior: 'smooth' // เลื่อนด้วยการเคลื่อนไหวแบบนุ่มนวล
        });

        (async () => {
            await getFavouritesGames();
            await getLastPlayGames();
            loadGameData();
        })();
    }, [game]);

    const getFavouritesGames = async () => {
        if(!cookies.get('access_token'))
        {
            return;
        }
        try {
            const service = new ApiService();
            const favsList = await service.getFavouritesGames(cookies.get('access_token'));
            
            if(favsList.data.status_code === 200)
            {
                setFavouritesGame(favsList.data.favourites);
            }
        } catch (err) {
            console.log(err);
        }
    }

    const getLastPlayGames = async () => {
        if(!cookies.get('access_token'))
        {
            return;
        }

        try {
            const service = new ApiService();
            const lastPlayList = await service.getLastPlayGames(cookies.get('access_token'));
            
            if(lastPlayList.data.status_code === 200)
            {
                setLastPlayGame(lastPlayList.data.last_play);
            }
        } catch (err) {
            console.log(err);
        }
    }

    const addFavouriteGame = async (gameId: string, gameType: string) => {
        if(!cookies.get('access_token'))
        {
            return Swal.fire({
                icon: 'error',
                title: 'แจ้งเตือน',
                text: 'กรุณาเข้าสู่ระบบก่อน'
            });
        }

        setAddingFav(true);
        try {
            const service = new ApiService();
            const addFavList = await service.addFavouritesGames(cookies.get('access_token'), gameId, gameType);
            
            if(addFavList.data.status_code === 200)
            {
                getFavouritesGames();
                toast.success('เพิ่มรายการโปรดเรียบร้อยแล้วค่ะ');
            }
            else
            {
                Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    text: 'ไม่สามารถเพิ่มรายการโปรดได้ขณะนี้ กรุณาลองใหม่ภายหลัง'
                });
            }
        } catch (err) {
            console.log(err);
        }
        setAddingFav(false);
    }

    const deleteFavouriteGame = async (gameId: string, gameType: string) => {
        if(!cookies.get('access_token'))
        {
            return Swal.fire({
                icon: 'error',
                title: 'แจ้งเตือน',
                text: 'กรุณาเข้าสู่ระบบก่อน'
            });
        }

        setAddingFav(true);
        try {
            const service = new ApiService();
            const addFavList = await service.deleteFavouritesGames(cookies.get('access_token'), gameId, gameType);
            
            if(addFavList.data.status_code === 200)
            {
                getFavouritesGames();
                toast.success('ลบรายการโปรดเรียบร้อยแล้วค่ะ');
            }
            else
            {
                Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    text: `ไม่สามารถลบรายการโปรดได้ขณะนี้ (${addFavList.data.status_code} - ${addFavList.data.message})`
                });
            }
        } catch (err) {
            console.log(err);
        }
        setAddingFav(false);
    }

    const loadGameData = async () => {
        setLoading(true);
        try {
            const service = new ApiService();
            
            if(game !== 'favorites' && game !== 'last_play')
            {
                setProviders([]);
                const getProviders = await service.getProvidersList(game);
                const gamesData = await service.gameGamesList(game);
                
                if(gamesData.data.status_code === 200)
                {
                    const providersData = getProviders.data.providers.filter((element: providersType) => {
                        return element.provider, gamesData.data.games.findIndex((element2: gameListType) => element2.provider === element.provider) !== -1 ? true : false;
                    });

                    setProviders(providersData);
                    setTimeout(() => {
                        setGameList(gamesData.data.games);
                        setLoading(false);
                    }, 1000);
                }
                else
                {
                    Swal.fire({
                        icon: 'error',
                        title: 'แจ้งเตือน',
                        text: 'ไม่สามารถโหลดข้อมูลเกมได้ในขณะนี้ กรุณาลองรีเฟรชหน้าเว็บใหม่อีกครั้ง'
                    });
                }
            }
            else if(game === 'favorites' || game === 'last_play')
            {
                let list_game: gameListType[] = [];

                for(const element of ["slot", "casino", "fish"])
                {
                    const gamesData = await service.gameGamesList(element);
                    if(gamesData.data.status_code === 200)
                    {
                        const getLists = gamesData.data.games;
                        list_game = [...list_game, ...getLists];
                    }
                }

                setGameList(list_game);
                setLoading(false);
            }
        } catch (err) {
            console.log(err);
        }
    }

    const createRedirect = async (game_id: string, provider: string, tab: string) => {
        if(loading)
        {
            return;
        }

        setLoading(true);
        try {
            if(!cookies.get('access_token'))
            {
                setLoading(false);
                return Swal.fire({
                    icon: 'warning',
                    title: 'แจ้งเตือน',
                    text: 'กรุณาเข้าสู่ระบบก่อนเข้าเล่นค่ะ'
                });
            }

            const service = new ApiService();
            let getRedirectGameUrl = await service.getRedirectGameUrl(cookies.get('access_token'), game_id, provider, tab);

            if(getRedirectGameUrl.data.status_code !== 200)
            {
                getRedirectGameUrl = await service.getRedirectLoginUrl(cookies.get('access_token'));
            }

            const userAgent = navigator.userAgent.toLowerCase();
            const safari = /safari/.test(userAgent) && !/chrome/.test(userAgent);

            if(safari)
            {
                window.location.href = getRedirectGameUrl.data.url;
            }
            else
            {
                const link = document.createElement('a');
                link.href = getRedirectGameUrl.data.url;
                link.target = '_blank';
                link.click();
            }
        } catch (err) {
            console.log(err);
        }
        setLoading(false);
    }

    // const toggleVisibleProviders = () => {
    //     const element = document.getElementById("provider_list") as HTMLElement;
    //     const elementProviderListMain = document.getElementById("provider_list_main") as HTMLElement;
    //     if(!element)
    //     {
    //         return;
    //     }

    //     const stickyOffset = element.offsetTop;
    //     const setTop = 60;

    //     const pageHeight = document.documentElement.scrollHeight;

    //     if(window.pageYOffset > (pageHeight - 1300))
    //     {
    //         // console.log(1)
    //         setProviderBottom(true);
    //         element.style.position = 'static';
    //     }
    //     else if (window.pageYOffset > (stickyOffset > setTop ? stickyOffset : providerOffsets)) {
    //         // console.log(2)
    //         setProviderBottom(false);
    //         if(stickyOffset > setTop)
    //         {
    //             setProviderOffsets(stickyOffset);
    //         }
    //         element.style.position = 'fixed';
    //         element.style.top = `${setTop}px`;
    //         element.style.width = '50px';
    //     }
    //     else
    //     {
    //         // console.log(3)
    //         setProviderBottom(false);
    //         element.style.position = 'static';
    //     }
    // }

    // window.addEventListener('scroll', toggleVisibleProviders);

    return (
        !loading ? <Grid item xs={12}>
            <Toaster />
            <Container sx={{ maxWidth: '1300px !important' }}>
                {
                    game === 'empty-tab' ? <Grid container spacing={2}>
                        {
                            providers.sort((a: providersType, b: providersType) => {
                                if (a.provider === "SEX") return -1;
                                if (b.provider === "SEX") return 1;
                                if (a.provider === "PTGC") return -1;
                                if (b.provider === "PTGC") return 1;
                                if (a.provider === "SAG") return -1;
                                if (b.provider === "SAG") return 1;
                                if (a.provider === "AG") return -1;
                                if (b.provider === "AG") return 1;
                                if (a.provider === "BIGG") return -1;
                                if (b.provider === "BIGG") return 1;
                                if (a.provider === "WM") return -1;
                                if (b.provider === "WM") return 1;
                                if (a.provider === "PMT") return -1;
                                if (b.provider === "PMT") return 1;
                                if (a.provider === "AB") return -1;
                                if (b.provider === "AB") return 1;

                                return 0;
                            }).map((element: providersType, index: number) => {
                                return (
                                    <>
                                        <Grid item xs={12} key={`banner-${element.providerName}`}>
                                            <Box display={`flex`} justifyContent={`center`}>
                                                <img src={`${process.env.REACT_APP_BASE_URL}/img/banner/casino/${element.provider}.jpg`} alt={`${index}`} style={{
                                                    maxWidth: '1300px',
                                                    width: '100%',
                                                    borderRadius: '10px 10px 10px 10px',
                                                    boxShadow: '10px 10px 10px rgba(0, 0, 0, 0.1)'
                                                }} />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} key={`gamelist-${element.providerName}`}>
                                            <Swiper
                                                slidesPerView={isDesktopOrLaptop ? 8.5 : 4.5}
                                                spaceBetween={10}
                                                className="top-10-game-swiper"
                                            >
                                                {
                                                    gameList.filter((element2: gameListType) => element2.provider === element.provider).map((element2: gameListType, index: number) => {
                                                        return (
                                                            <SwiperSlide key={`casino-${game}-${element2.gameName}-${index}`}>
                                                                <Box sx={
                                                                    {
                                                                        cursor: 'pointer'   
                                                                    }
                                                                }>
                                                                    <Box style={{ position: 'relative', textAlign: 'center' }}>
                                                                        <Box sx={{
                                                                            position: 'absolute',
                                                                            backgroundColor: 'rgba(0, 0, 0, .5)',
                                                                            height: '100%',
                                                                            width: '100%',
                                                                            color: 'black',
                                                                            opacity: '0%',
                                                                            borderRadius: 2,
                                                                            zIndex: 10,
                                                                            transition: 'all .2s',
                                                                            '&:hover': {
                                                                                opacity: '100%'
                                                                            },
                                                                            display: 'flex',
                                                                            alignContent: 'center',
                                                                            alignItems: 'center'
                                                                        }}>
                                                                            <Box width={`100%`} display={`flex`} justifyContent={`center`}>
                                                                                <Button sx={{
                                                                                    backgroundColor: '#570394',
                                                                                    color: 'white',
                                                                                    '&:hover': {
                                                                                        backgroundColor: '#570394'
                                                                                    }
                                                                                }} onClick={() => {
                                                                                    createRedirect(element2.game_id, element2.provider, element2.gameCategory)
                                                                                }}>
                                                                                    <Typography align="center" display={`flex`} alignContent={`center`} alignItems={`center`} py={.3} px={1} fontSize={14}>
                                                                                        <FaPlay />&nbsp;เข้าเล่น
                                                                                    </Typography>
                                                                                </Button>
                                                                            </Box>
                                                                        </Box>
                                                                        <img
                                                                            src={element2.image_vertical}
                                                                            alt={element2.gameName}
                                                                            style={{ 
                                                                                borderRadius: 10,
                                                                                width: `100%`,
                                                                                maxHeight: 200, 
                                                                                display: 'block' 
                                                                            }}
                                                                        />
                                                                        <div style={{
                                                                            position: 'absolute',
                                                                            bottom: '0',
                                                                            color: 'white',
                                                                            background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, .8), rgba(0, 0, 0, 1))',
                                                                            transform: 'translate(-50%, 0%)',
                                                                            left: '50%',
                                                                            padding: '10px',
                                                                            borderRadius: '5px',
                                                                            maxWidth: '87%',
                                                                            minWidth: '50%',
                                                                            width: '100%'
                                                                        }}>
                                                                            <Typography fontSize={12} align='center'>
                                                                                {
                                                                                    element2.provider
                                                                                }
                                                                                &nbsp;
                                                                                {
                                                                                    element2.gameName
                                                                                }
                                                                            </Typography>
                                                                        </div>
                                                                    </Box>
                                                                </Box>
                                                            </SwiperSlide>
                                                        )
                                                    })
                                                }
                                            </Swiper>
                                        </Grid>
                                        {
                                            (index + 1) < providers.length && <Grid item xs={12}>
                                                <Divider />
                                            </Grid>
                                        }
                                    </>
                                )
                            })
                        }
                    </Grid> : (
                        (game !== 'favorites' && game !== 'last_play') ? <>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sx={{ position: 'relative' }}>
                                    <div className="rfm-overlay"></div>
                                    <Swiper
                                        slidesPerView={isDesktopOrLaptop ? 14 : 6}
                                        spaceBetween={10}
                                        slidesPerGroup={isDesktopOrLaptop ? 7 : 3}
                                        className="top-10-game-swiper"
                                        navigation={true} modules={[Navigation]}
                                    >
                                        
                                        {
                                            providers.sort((a: providersType, b: providersType) => {
                                                if(game === 'slot')
                                                {
                                                    if (a.provider === "PGS") return -1;
                                                    if (b.provider === "PGS") return 1;
                                                    if (a.provider === "PMTS") return -1;
                                                    if (b.provider === "PMTS") return 1;
                                                    if (a.provider === "PMT") return -1;
                                                    if (b.provider === "PMT") return 1;
                                                    if (a.provider === "NJK") return -1;
                                                    if (b.provider === "NJK") return 1;
                                                    if (a.provider === "JL") return -1;
                                                    if (b.provider === "JL") return 1;
                                                    if (a.provider === "SEXS") return -1;
                                                    if (b.provider === "SEXS") return 1;
                                                    if (a.provider === "JOK") return -1;
                                                    if (b.provider === "JOK") return 1;
                                                    if (a.provider === "L22") return -1;
                                                    if (b.provider === "L22") return 1;
                                                    if (a.provider === "AMBS") return -1;
                                                    if (b.provider === "AMBS") return 1;
                                                }
                                                else if(game === 'casino')
                                                {
                                                    if (a.provider === "SEX") return -1;
                                                    if (b.provider === "SEX") return 1;
                                                    if (a.provider === "PTGC") return -1;
                                                    if (b.provider === "PTGC") return 1;
                                                    if (a.provider === "SAG") return -1;
                                                    if (b.provider === "SAG") return 1;
                                                    if (a.provider === "AG") return -1;
                                                    if (b.provider === "AG") return 1;
                                                    if (a.provider === "BIGG") return -1;
                                                    if (b.provider === "BIGG") return 1;
                                                    if (a.provider === "WM") return -1;
                                                    if (b.provider === "WM") return 1;
                                                    if (a.provider === "PMT") return -1;
                                                    if (b.provider === "PMT") return 1;
                                                    if (a.provider === "AB") return -1;
                                                    if (b.provider === "AB") return 1;
                                                }

                                                return 0;
                                            }).map((element: providersType, index: number) => {
                                                return (
                                                    <SwiperSlide key={index}>
                                                        <Box sx={
                                                            {
                                                                cursor: 'pointer'   
                                                            }
                                                        } onClick={() => setCurrentProviders(index)}>
                                                            <img
                                                                src={element.logoURL}
                                                                alt={`${element.providerName}`}
                                                                className="hover-scale"
                                                                style={{ 
                                                                    width: `100%`,
                                                                    maxHeight: 200,
                                                                    display: 'block',
                                                                    transition: 'all .3s'
                                                                }}
                                                            />
                                                        </Box>
                                                    </SwiperSlide>
                                                )
                                            })
                                        }
                                        
                                    </Swiper>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Grid container spacing={2} id="game_list">
                                        {
                                            gameList.filter((element: gameListType) => element.provider === providers[currentProviders].provider).sort((a: gameListType, b: gameListType) => {
                                                if (favouritesGame.findIndex((favElement: favouritesGame) => favElement.game_id === a.game_id && favElement.game_category === a.gameCategory) !== -1) return -1;
                                                if (favouritesGame.findIndex((favElement: favouritesGame) => favElement.game_id === b.game_id && favElement.game_category === b.gameCategory) !== -1) return 1;

                                                return 0;
                                            }).map((element: gameListType, index: number) => {
                                                return (
                                                    <Grid item xs={4} sm={3} md={2} lg={1.5} key={`${element.gameName}-${index}`}>
                                                        <Box>
                                                            <Box style={{ position: 'relative', textAlign: 'center' }}>
                                                                <Box sx={{
                                                                    position: 'absolute',
                                                                    backgroundColor: 'rgba(0, 0, 0, .5)',
                                                                    height: '100%',
                                                                    width: '100%',
                                                                    color: 'black',
                                                                    opacity: '0%',
                                                                    borderRadius: 2,
                                                                    zIndex: 10,
                                                                    transition: 'all .2s',
                                                                    '&:hover': {
                                                                        opacity: '100%'
                                                                    },
                                                                    display: 'flex',
                                                                    alignContent: 'center',
                                                                    alignItems: 'center'
                                                                }}>
                                                                    <Box width={`100%`} display={`flex`} flexDirection={`column`} gap={.5} justifyContent={`center`} px={1}>
                                                                        <Button sx={{
                                                                            backgroundColor: '#570394',
                                                                            color: 'white',
                                                                            cursor: 'pointer',
                                                                            '&:hover': {
                                                                                backgroundColor: '#7919bf'
                                                                            },
                                                                            display: 'flex',
                                                                            justifyContent: 'center'
                                                                        }} onClick={() => {
                                                                            createRedirect(element.game_id, element.provider, element.gameCategory)
                                                                        }}>
                                                                            <Typography align="center" display={`flex`} alignContent={`center`} alignItems={`center`} py={.3} px={1} fontSize={14}>
                                                                                <FaPlay />&nbsp;เข้าเล่น
                                                                            </Typography>
                                                                        </Button>
                                                                        {
                                                                            cookies.get('access_token') ? <Button sx={{
                                                                                backgroundColor: '#570394',
                                                                                color: 'white',
                                                                                cursor: 'pointer',
                                                                                '&:hover': {
                                                                                    backgroundColor: '#7919bf'
                                                                                },
                                                                                display: 'flex',
                                                                                justifyContent: 'center'
                                                                            }} onClick={() => {
                                                                                if(favouritesGame.findIndex((favElement: favouritesGame) => favElement.game_id === element.game_id && favElement.game_category === element.gameCategory) !== -1)
                                                                                {
                                                                                    deleteFavouriteGame(element.game_id, element.gameCategory);
                                                                                }
                                                                                else
                                                                                {
                                                                                    addFavouriteGame(element.game_id, element.gameCategory);
                                                                                }
                                                                            }} disabled={addingFav}>
                                                                                <Typography align="center" display={`flex`} alignContent={`center`} alignItems={`center`} py={.3} px={1} fontSize={14}>
                                                                                    {
                                                                                        (favouritesGame.length > 0 && favouritesGame.findIndex((favElement: favouritesGame) => favElement.game_id === element.game_id && favElement.game_category === element.gameCategory) !== -1) ? <>
                                                                                            <TiHeartFullOutline />&nbsp;ลบรายการโปรด
                                                                                        </> : <>
                                                                                            <FaRegHeart />&nbsp;เพิ่มรายการโปรด
                                                                                        </>
                                                                                    }
                                                                                </Typography>
                                                                            </Button> : null
                                                                        }
                                                                    </Box>
                                                                </Box>
                                                                <img
                                                                    src={element.image_vertical}
                                                                    alt={element.gameName}
                                                                    style={{ 
                                                                        borderRadius: 10,
                                                                        width: `100%`,
                                                                        maxHeight: 200, 
                                                                        display: 'block' 
                                                                    }}
                                                                />
                                                                <div style={{
                                                                    position: 'absolute',
                                                                    bottom: '0',
                                                                    color: 'white',
                                                                    background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, .8), rgba(0, 0, 0, 1))',
                                                                    transform: 'translate(-50%, 0%)',
                                                                    left: '50%',
                                                                    padding: '10px',
                                                                    borderRadius: '5px',
                                                                    maxWidth: '87%',
                                                                    minWidth: '50%',
                                                                    width: '100%'
                                                                }}>
                                                                    <Typography fontSize={12} align='center'>
                                                                        {
                                                                            element.provider
                                                                        }
                                                                        &nbsp;
                                                                        {
                                                                            element.gameName
                                                                        }
                                                                    </Typography>
                                                                </div>
                                                            </Box>
                                                        </Box>
                                                    </Grid>
                                                )
                                            })
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </> : (
                            (game === 'favorites' || game === 'last_play') ? <>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Grid container spacing={2} id="game_list">
                                            {
                                                gameList
                                                    .filter((element: gameListType) => {
                                                        if(game === 'favorites')
                                                        {
                                                            return favouritesGame.findIndex((favElement: favouritesGame) => favElement.game_id === element.game_id) !== -1
                                                        }
                                                        else if(game === 'last_play')
                                                        {
                                                            return lastPlayGame.findIndex((lastplayElement: favouritesGame) => lastplayElement.game_id === element.game_id) !== -1
                                                        }

                                                        return false;
                                                    })
                                                    .map((element: gameListType, index: number) => {
                                                    return (
                                                        <Grid item xs={4} sm={3} md={2} lg={1.5} key={`${element.gameName}-${index}`}>
                                                            <Box>
                                                                <Box style={{ position: 'relative', textAlign: 'center' }}>
                                                                    <Box sx={{
                                                                        position: 'absolute',
                                                                        backgroundColor: 'rgba(0, 0, 0, .5)',
                                                                        height: '100%',
                                                                        width: '100%',
                                                                        color: 'black',
                                                                        opacity: '0%',
                                                                        borderRadius: 2,
                                                                        zIndex: 10,
                                                                        transition: 'all .2s',
                                                                        '&:hover': {
                                                                            opacity: '100%'
                                                                        },
                                                                        display: 'flex',
                                                                        alignContent: 'center',
                                                                        alignItems: 'center'
                                                                    }}>
                                                                        <Box width={`100%`} display={`flex`} flexDirection={`column`} gap={.5} justifyContent={`center`} px={1}>
                                                                            <Button sx={{
                                                                                backgroundColor: '#570394',
                                                                                color: 'white',
                                                                                cursor: 'pointer',
                                                                                '&:hover': {
                                                                                    backgroundColor: '#7919bf'
                                                                                },
                                                                                display: 'flex',
                                                                                justifyContent: 'center'
                                                                            }} onClick={() => {
                                                                                createRedirect(element.game_id, element.provider, element.gameCategory)
                                                                            }}>
                                                                                <Typography align="center" display={`flex`} alignContent={`center`} alignItems={`center`} py={.3} px={1} fontSize={14}>
                                                                                    <FaPlay />&nbsp;เข้าเล่น
                                                                                </Typography>
                                                                            </Button>
                                                                            {
                                                                                cookies.get('access_token') ? <Button sx={{
                                                                                    backgroundColor: '#570394',
                                                                                    color: 'white',
                                                                                    cursor: 'pointer',
                                                                                    '&:hover': {
                                                                                        backgroundColor: '#7919bf'
                                                                                    },
                                                                                    display: 'flex',
                                                                                    justifyContent: 'center'
                                                                                }} onClick={() => {
                                                                                    if(favouritesGame.findIndex((favElement: favouritesGame) => favElement.game_id === element.game_id && favElement.game_category === element.gameCategory) !== -1)
                                                                                    {
                                                                                        deleteFavouriteGame(element.game_id, element.gameCategory);
                                                                                    }
                                                                                    else
                                                                                    {
                                                                                        addFavouriteGame(element.game_id, element.gameCategory);
                                                                                    }
                                                                                }} disabled={addingFav}>
                                                                                    <Typography align="center" display={`flex`} alignContent={`center`} alignItems={`center`} py={.3} px={1} fontSize={14}>
                                                                                        {
                                                                                            (favouritesGame.length > 0 && favouritesGame.findIndex((favElement: favouritesGame) => favElement.game_id === element.game_id && favElement.game_category === element.gameCategory) !== -1) ? <>
                                                                                                <TiHeartFullOutline />&nbsp;ลบรายการโปรด
                                                                                            </> : <>
                                                                                                <FaRegHeart />&nbsp;เพิ่มรายการโปรด
                                                                                            </>
                                                                                        }
                                                                                    </Typography>
                                                                                </Button> : null
                                                                            }
                                                                        </Box>
                                                                    </Box>
                                                                    <img
                                                                        src={element.image_vertical}
                                                                        alt={element.gameName}
                                                                        style={{ 
                                                                            borderRadius: 10,
                                                                            width: `100%`,
                                                                            maxHeight: 200, 
                                                                            display: 'block' 
                                                                        }}
                                                                    />
                                                                    <div style={{
                                                                        position: 'absolute',
                                                                        bottom: '0',
                                                                        color: 'white',
                                                                        background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, .8), rgba(0, 0, 0, 1))',
                                                                        transform: 'translate(-50%, 0%)',
                                                                        left: '50%',
                                                                        padding: '10px',
                                                                        borderRadius: '5px',
                                                                        maxWidth: '87%',
                                                                        minWidth: '50%',
                                                                        width: '100%'
                                                                    }}>
                                                                        <Typography fontSize={12} align='center'>
                                                                            {
                                                                                element.provider
                                                                            }
                                                                            &nbsp;
                                                                            {
                                                                                element.gameName
                                                                            }
                                                                        </Typography>
                                                                    </div>
                                                                </Box>
                                                            </Box>
                                                        </Grid>
                                                    )
                                                })
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </> : <></>
                        )
                    )
                }
            </Container>
        </Grid> : <Box width={`100%`} display={`flex`} justifyContent={`center`} alignContent={`center`} alignItems={`center`} mt={3}>
            <DiceSpin />
        </Box>
    )
}

export default GameTab;