import axios, { AxiosInstance, AxiosResponse, AxiosRequestConfig } from "axios";
import Cookies from "universal-cookie";
import { loginFormType, createMemberType, lottoPoyType } from './../types';

export class ApiService {
    private baseURL: string | undefined;
    private instance: AxiosInstance;

    constructor(timeout?: number | undefined | null) {
        this.baseURL = process.env.REACT_APP_API_URL;
        this.instance = axios.create({
            baseURL: this.baseURL,
            timeout: timeout || 120 * 1000,
            timeoutErrorMessage: "Time out!",
        });
    }

    getBaseURL = (): string | undefined => {
        return this.baseURL;
    }

    get = async (path: string, config?: AxiosRequestConfig): Promise<AxiosResponse> => {
        return this.instance.get(path, config).then((res: AxiosResponse) => {
            return res;
        });
    }

    post = async (path: string, body: any, config?: AxiosRequestConfig): Promise<AxiosResponse> => {
        return this.instance.post(path, body, config).then((res: AxiosResponse) => {
            return res;
        });
    }

    check_access_token = async(access_token: string): Promise<AxiosResponse> => {
        return this.instance.post('/member/checkaccesstoken', {}, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    login = async(body: loginFormType, config?: AxiosRequestConfig): Promise<AxiosResponse> => {
        return this.instance.post('/member/login', body, config).then((res: AxiosResponse) => {
            return res;
        });
    }

    register = async (createMember: createMemberType): Promise<AxiosResponse> => {
        const cookies = new Cookies(null, {path: '/'});

        return this.instance.post('/member/register', {
            password: createMember.password,
            phone: createMember.phone,
            first_name: createMember.first_name,
            last_name: createMember.last_name,
            line: createMember.line,
            know_at: createMember.know_at,
            bank: createMember.bank,
            bank_number: createMember.bank_number,
            referrer: cookies.get('referrer') ? cookies.get('referrer') : null,
            by: createMember.by ? createMember.by : null,
            aff: createMember.aff ? createMember.aff : null
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    getMemberData = async (access_token: string): Promise<AxiosResponse> => {
        return this.instance.get('/member/get/data', {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    getMemberDetail = async (access_token: string): Promise<AxiosResponse> => {
        return this.instance.get('/member/get/detail', {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    getMemberDetailPopup = async (access_token: string): Promise<AxiosResponse> => {
        return this.instance.get('/member/detail', {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    getCurrentCredit = async (access_token: string): Promise<AxiosResponse> => {
        return this.instance.get('/member/get/currentcredit', {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    requestPay = async (access_token: string, amount: string, path: string, endpoint: string): Promise<AxiosResponse> => {
        return this.instance.post(`${path}${endpoint}`, {
            amount: amount
        }, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    getEndpointPay = async (): Promise<AxiosResponse> => {
        return this.instance.get('/member/get/endpoint', {}).then((res: AxiosResponse) => {
            return res;
        });
    }

    requestPayout = async (access_token: string, amount: string, path: string, endpoint: string): Promise<AxiosResponse> => {
        return this.instance.post(`${path}${endpoint}`, {
            amount: amount
        }, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    requestPayoutZPay = async (access_token: string, amount: string): Promise<AxiosResponse> => {
        return this.instance.post('/zpay/create/payout', {
            amount: amount
        }, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    checkDepositTransaction = async (access_token: string, transaction_id: number): Promise<AxiosResponse> => {
        return this.instance.get(`/member/checkDepositTransaction/${transaction_id}`, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    getAccountActive = async (access_token: string): Promise<AxiosResponse> => {
        return this.instance.get(`/scb/get/account`, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    getListPromotion = async (): Promise<AxiosResponse> => {
        return this.instance.get(`/member/get/promotion`, {}).then((res: AxiosResponse) => {
            return res;
        });
    }

    getPromotion = async (access_token: string, promotion_id: number): Promise<AxiosResponse> => {
        return this.instance.post(`/member/get/promotion`, {
            promotion_id: promotion_id
        }, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    getPlayUrl = async (access_token: string): Promise<AxiosResponse> => {
        return this.instance.get(`/member/get/redirectlogin`, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    getGameList = async (): Promise<AxiosResponse> => {
        return this.instance.get(`/member/get/gamelist`, {}).then((res: AxiosResponse) => {
            return res;
        });
    }

    getProvidersList = async (gameCategory: string): Promise<AxiosResponse> => {
        return this.instance.get(`/member/get/providers/${gameCategory}`, {}).then((res: AxiosResponse) => {
            return res;
        });
    }

    getRedirectLoginUrl = async (access_token: string): Promise<AxiosResponse> => {
        return this.instance.get(`/member/get/redirectlogin`, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    getRedirectGameUrl = async (access_token: string, game_id: string, provider: string, tab: string): Promise<AxiosResponse> => {
        return this.instance.post(`/member/get/startgameurl`, {
            game_id,
            provider,
            tab
        }, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    getRedirectLotto = async (access_token: string): Promise<AxiosResponse> => {
        return this.instance.post(`/member/get/startgamelotto`, {}, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    getReturnWinloseCreditData = async (access_token: string): Promise<AxiosResponse> => {
        return this.instance.get(`/member/get/returncredit`, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    getReturnWinloseCredit = async (access_token: string): Promise<AxiosResponse> => {
        return this.instance.post(`/member/get/returncredit`, {}, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    getReturnFriendWinloseCreditData = async (access_token: string): Promise<AxiosResponse> => {
        return this.instance.get(`/member/get/returnfriendcredit`, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    getReturnFriendWinloseCredit = async (access_token: string): Promise<AxiosResponse> => {
        return this.instance.post(`/member/get/returnfriendcredit`, {}, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    addClickCount = async (reg_by: string): Promise<AxiosResponse> => {
        return this.instance.get(`/click/${reg_by}`, {}).then((res: AxiosResponse) => {
            return res;
        });
    }

    getLottoDetail = async (lotto_id: number): Promise<AxiosResponse> => {
        return this.instance.get(`/lotto/get/${lotto_id}`, {}).then((res: AxiosResponse) => {
            return res;
        });
    }

    getMemberAffiliateUrl = async (access_token: string): Promise<AxiosResponse> => {
        return this.instance.get(`/member/get/affiliate`, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    getLottoList = async (): Promise<AxiosResponse> => {
        return this.instance.get(`/lotto/list`, {}).then((res: AxiosResponse) => {
            return res;
        });
    }

    getLottoAllResults = async (): Promise<AxiosResponse> => {
        return this.instance.get(`/lotto/results`, {}).then((res: AxiosResponse) => {
            return res;
        });
    }

    gameGamesList = async (game: string): Promise<AxiosResponse> => {
        return this.instance.get(`/games/get/${game}`, {}).then((res: AxiosResponse) => {
            return res;
        });
    }

    getFavouritesGames = async (access_token: string): Promise<AxiosResponse> => {
        return this.instance.get(`/member/favourites/games`, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    getLastPlayGames = async (access_token: string): Promise<AxiosResponse> => {
        return this.instance.get(`/member/lastplay/games`, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    addFavouritesGames = async (access_token: string, gameId: string, gameType: string): Promise<AxiosResponse> => {
        return this.instance.post(`/member/favourites/games`, {
            gameId: gameId,
            gameType: gameType
        }, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    deleteFavouritesGames = async (access_token: string, gameId: string, gameType: string): Promise<AxiosResponse> => {
        return this.instance.post(`/member/delete/favourites/games`, {
            gameId: gameId,
            gameType: gameType
        }, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    createLottoBill = async (access_token: string, lotto_id: number, bill: lottoPoyType[]): Promise<AxiosResponse> => {
        return this.instance.post(`/lotto/create/bill`, {
            lotto_id: lotto_id,
            bill: bill
        }, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    getMemberBills = async (access_token: string): Promise<AxiosResponse> => {
        return this.instance.get(`/lotto/bills`, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    getDepositTransaction = async (access_token: string): Promise<AxiosResponse> => {
        return this.instance.get(`/member/deposit/transactions`, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    getWithdrawTransaction = async (access_token: string): Promise<AxiosResponse> => {
        return this.instance.get(`/member/withdraw/transactions`, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    getBillById = async (access_token: string, billId: number): Promise<AxiosResponse> => {
        return this.instance.get(`/member/get/bill/${billId}`, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    createPaymentScb = async (access_token: string, amount: number): Promise<AxiosResponse> => {
        return this.instance.post(`/scbpython/create/transaction`, {
            amount: amount
        }, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    getLogPromotion = async (access_token: string): Promise<AxiosResponse> => {
        return this.instance.get(`/member/log/promotion`, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }
}